//import firebase from "firebase/app";
//import "firebase/storage";

import firebase from "firebase/compat/app";
import "firebase/compat/storage"

let firebaseConfig = {
    apiKey: "AIzaSyCJj675kfpqbCSXehPxgcxQ1v-aEB3Gae8",
    authDomain: "transparencia-d917a.firebaseapp.com",
    projectId: "transparencia-d917a",
    storageBucket: "transparencia-d917a.appspot.com",
    messagingSenderId: "761273460955",
    appId: "1:761273460955:web:eb1f24ed5f6f59eab784fe",
    measurementId: "G-90Z6JBYBSP"
};

firebase.initializeApp(firebaseConfig);

const firebaseApp = firebase;

export default firebaseApp;

/*
apiKey: "AIzaSyAlqaI2iktVGb4lt6VvdEsbSqshck4R3Ys",
authDomain: "portaria-sespa.firebaseapp.com",
projectId: "portaria-sespa",
storageBucket: "portaria-sespa.appspot.com",
messagingSenderId: "947418013751",
appId: "1:947418013751:web:e5291fe8410c01684d1fb2",
measurementId: "G-B1W7822TWD"
*/