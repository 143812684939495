import axios from "axios";
import AuthService from '../Auth/AuthService';

export default class RegistroChamamentoService extends AuthService {

    findRegistroChamamentoById(id) {
        return axios({
            method: 'get',
            url: this.api_url + 'registro-chamamento/' + id,
            withCredentials: true,
        }).then(res => res.data);
    }
    create(data) {
        return axios({
            method: 'post',
            url: this.api_url + 'registro-chamamento',
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    update(id, data) {
        return axios({
            method: 'put',
            url: this.api_url + 'registro-chamamento/' + id,
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    delete(id) {
        return axios({
            method: 'delete',
            url: this.api_url + 'registro-chamamento/' + id,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }

}
