export default function msgErro(response) {
    this.mensagemDesenvolvedor = response.response.data[0].mensagemDesenvolvedor;
    this.mensagemUsuario = response.response.data[0].mensagemUsuario;

    let containing = this.mensagemDesenvolvedor.indexOf('NegocioException');
    let foreignKey = this.mensagemDesenvolvedor.indexOf('java.sql.SQLIntegrityConstraintViolationException');
    if (containing > -1) {
        let negocio = this.mensagemDesenvolvedor.replace('br.gov.pa.saude.api.exceptionhandler.NegocioException:', '');
        //this.mensagemUsuario = negocio
        this.$toast.add({
            severity: "error",
            summary: "Alerta de Erro.",
            detail: negocio,
        });
    }
    if (foreignKey > -1) {
        this.$toast.add({
            severity: "error",
            summary: "Alerta de Erro.",
            detail: "Não é possivel excluir registros cujo estão dados atrelados.",
        });
    }
}