import axios from "axios";
import AuthService from '../Auth/AuthService';
//import config from '../Auth/Config';

export default class ColumsService extends AuthService {

    findAll() {
        return axios({
            method: 'get',
            url: this.api_url + 'colums',
            withCredentials: true,
        }).then(res => res.data);
    }
    findByDatatableId(codigo) {
        return axios({
            method: 'get',
            url: this.api_url + 'colums/datatable/' + codigo,
            withCredentials: true,
        }).then(res => res.data);
    }
    create(dados) {
        return axios({
            method: 'post',
            url: this.api_url + 'colums',
            data: dados,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    update(codigo, data) {
        return axios({
            method: 'put',
            url: this.api_url + 'colums/' + codigo,
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    delete(codigo) {
        return axios({
            method: 'delete',
            url: this.api_url + 'colums/' + codigo,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
}
