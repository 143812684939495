import AuthService from "@/service/Auth/AuthService";

const authService = new AuthService();

export default function msgWarn(message) {
    if (authService.verificarTokenExpirado()) {
        message = "Secção expirada, efetue login novamente."
        this.$toast.add({
            severity: "warn",
            summary: "Atenção.",
            detail: message,
        });
    }
}