import { createRouter, createWebHashHistory } from 'vue-router';
import Login from './pages/Login.vue';
import Preview from './pages/datatable/Preview.vue';
import Empenho from './pages/consulta/despesas-empenho.vue';
import Orcamentaria from './pages/consulta/despesas-orcamentaria.vue';
import Diarias from './pages/consulta/despesas-diarias.vue';
import ChamamentoAberto from './pages/chamamento/chamamento-aberto.vue';
import ChamamentoFechado from './pages/chamamento/chamamento-fechado.vue';
import DatatableExterno from './pages/datatable/Externo.vue';
import LinksExteno from './pages/links/Externo.vue';


const routes = [

    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/preview',
        name: 'preview',
        component: Preview,
    },
    {
        path: '/',
        name: 'datatables-externo',
        component: DatatableExterno,
    },
    {
        path: '/links-externo',
        name: 'links-externo',
        component: LinksExteno,
    },

    {
        path: '/despesas-empenho',
        name: 'empenho',
        component: Empenho,
    },
    {
        path: '/despesas-orcamentaria',
        name: 'orcamentaria',
        component: Orcamentaria,
    },
    {
        path: '/despesas-diarias',
        name: 'diarias',
        component: Diarias,
    },

    {
        path: '/chamamento-aberto',
        name: 'chamamentoAberto',
        component: ChamamentoAberto,
    },
    {
        path: '/chamamento-fechado',
        name: 'chamamentoFechado',
        component: ChamamentoFechado,
    },

    {
        path: '/confirmation',
        name: 'confirmation',
        component: () => import('./pages/usuario/Confirmation.vue'),
    },
    {
        path: '/refreshPassword',
        name: 'refreshPassword',
        component: () => import('./pages/usuario/RefreshPassword.vue'),
    },
    {
        path: '/:catchAll(.*)*',
        name: 'Error404',
        component: () => import('./pages/Error404.vue')
    },
    {
        path: '/system',
        name: 'system',
        component: () => import('./pages/System.vue'),
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./pages/datatable/Registro.vue'),
            },
            {
                path: '/usuarios',
                name: 'usuarios',
                component: () => import('./pages/usuario/Gestao-Usuario.vue'),
            },
            {
                path: '/dados-usuario',
                name: 'usuario',
                component: () => import('./pages/usuario/Dados-Usuario.vue'),
            },
            {
                path: '/config-datatable',
                name: 'config',
                component: () => import('./pages/datatable/Datatable.vue'),
            },
            {
                path: '/registro-datatable',
                name: 'registro',
                component: () => import('./pages/datatable/Registro.vue'),
            },
            {
                path: '/log',
                name: 'log',
                component: () => import('./pages/log/Log.vue'),
            },
            {
                path: '/gruposAndPermissoes',
                name: 'gruposPermissoes',
                component: () => import('./pages/usuario/GruposAndPermissoes.vue'),
            },
            {
                path: '/links',
                component: () => import('./pages/links/Link.vue'),
            },
            {
                path: '/setor',
                component: () => import('./pages/setor/Setor.vue'),
            },
            {
                path: '/unidades',
                component: () => import('./pages/setor/Unidades.vue'),
            },

            {
                path: '/editais',
                component: () => import('./pages/chamamento/editais.vue'),
            },

            {
                path: '/registros-editais',
                component: () => import('./pages/chamamento/registros-por-editais.vue'),
            },
        


        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
