<template>
  <div class="p-col-12">
    <Panel :header="datatable.nome.toUpperCase()">
      <Toolbar class="p-mb-3">
        <template v-slot:left>
          <img src="../../assets/Brasao.png" alt="Logo Sespa" width="200" />
        </template>
        <template v-slot:right>
          <Button
            icon="pi pi-bookmark"
            class="p-button-rounded p-button-secondary p-mr-5"
            @click="showGropsDatatable"
          />
          <OverlayPanel
            ref="op"
            appendTo="body"
            :showCloseIcon="true"
            id="overlay_panel"
            style="width: 450px"
            :breakpoints="{ '960px': '75vw' }"
          >
            <h5><b> Responsáveis:</b></h5>
            <ul>
              <li v-for="responsaveis in responsaveis" :key="responsaveis.id">
                <small v-if="responsaveis.setor.id === 1"> </small>
                <small v-else>
                  <b>{{ responsaveis.nome.toUpperCase() }} - </b>
                  {{ responsaveis.setor.nome }}.
                </small>
              </li>
            </ul>
          </OverlayPanel>
          <Button
            icon="fa-solid fa-file-pdf"
            class="p-button-raised p-button-danger p-mr-3"
            label="PDF"
            @click="exportPDF()"
          />
          <Button
            icon="fa-solid fa-file-csv"
            class="p-button-raised p-button-info p-mr-3"
            label="CSV"
            @click="exportCSV()"
          />
        </template>
      </Toolbar>
      <DataTable
        dataKey="id"
        ref="dt"
        responsiveLayout="scroll"
        class="p-datatable-sm"
        :value="dados"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 50]"
        :filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} registros."
      >
        <template #header>
          <div
            class="
              table-header
              p-d-flex p-flex-column p-flex-md-row p-jc-md-between
            "
          >
            <h5>{{ datatable.nome.toUpperCase() }}</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Pesquisar..."
              />
            </span>
          </div>
        </template>
        <template #empty>
          <div class="p-text-center">Nenhum resultado encontrado...</div>
        </template>
        <Column
          v-for="col of columns"
          :field="col.field"
          :header="col.header.toUpperCase()"
          :key="col.type"
          :sortable="true"
        >
          <template #body="slotProps">
            <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->
            <div v-if="slotProps.column.key === 'NUMERAL'">
              <small>
                {{ slotProps.data[col.field] }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE NUMERO INTEIRO -->

            <!-- EM CASO DO TIPO DE UNIDADE_GESTORA -->
            <div v-if="slotProps.column.key === 'UNIDADE_GESTORA'">
              <small>
                {{ slotProps.data[col.field] }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE UNIDADE_GESTORA -->

            <!-- EM CASO DO TIPO DE TEXTO -->
            <div v-if="slotProps.column.key === 'LINK'">
              <small v-if="slotProps.data[col.field] === 'não se aplica'"  >
              -
              </small>
              <small v-else  >
                <a :href="slotProps.data[col.field]" target="_blank"
                  >Acessar Link</a
                >
              </small>
            </div>
            <!-- EM CASO DO TIPO DE TEXTO -->

            <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->
            <div v-if="slotProps.column.key === 'LONGTEXTO'">
              <div class="myBox">
                <small>
                  {{ slotProps.data[col.field].toUpperCase() }}
                </small>
              </div>
            </div>
            <!-- EM CASO DO TIPO DE TEXTO LONGO TEXTO -->

            <!-- EM CASO DO TIPO DE TEXTO -->
            <div v-if="slotProps.column.key === 'TEXTO'">
              <small v-tooltip.top="slotProps.data[col.field]">
                {{ slotProps.data[col.field].toUpperCase() }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE TEXTO -->

            <!-- EM CASO DO TIPO DE DATA -->
            <div v-if="slotProps.column.key === 'DATE'">
              <small>
                {{ $DateTime.formatDate(slotProps.data[col.field]) }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE DATA -->

            <!-- EM CASO DO TIPO DE HORA -->
            <div v-if="slotProps.column.key === 'TIME'">
              <small>
                {{ slotProps.data[col.field] }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE HORA -->

            <!-- EM CASO DO TIPO DE DATA E HORA -->
            <div v-if="slotProps.column.key === 'DATETIME'">
              <small>
                {{ $DateTime.formatDateTime24hours(slotProps.data[col.field]) }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE DATA E HORA -->

            <!-- EM CASO DO TIPO DE VALORES -->
            <div v-if="slotProps.column.key === 'VALOR'">
              <small>
                {{ slotProps.data[col.field] }}
              </small>
            </div>
            <!-- EM CASO DO TIPO DE VALORES -->

            <!-- EM CASO DO TIPO DE BOOLEAN -->
            <div v-if="slotProps.column.key === 'BOOLEAN'">
              <span
                v-if="slotProps.data[col.field] === 'true'"
                class="product-badge status-true"
                >ATIVO</span
              >
              <span
                v-if="slotProps.data[col.field] === 'false'"
                class="product-badge status-false"
                >Inativo</span
              >
            </div>
            <!-- EM CASO DO TIPO DE BOOLENAN -->

            <!-- CASO FOR UPLOAD -->
            <div v-if="slotProps.column.key === 'UPLOAD'">
              <Button
                label="ANEXO"
                class="p-button-raised p-button-secondary"
                icon="fa-solid fa-folder"
                @click="openAnexo(slotProps.data[col.field])"
              />
            </div>
            <!-- CASO FOR UPLOAD -->
          </template>
        </Column>
      </DataTable>
    </Panel>
  </div>

  <!-- MODAL DE ANEXOS -->
  <Dialog
    v-model:visible="dialogAnexo"
    header="LISTA DE ANEXOS"
    :style="{ width: '650px' }"
    :modal="true"
  >
    <ProgressBar
      v-if="progressbar"
      mode="indeterminate"
      style="height: 0.5em"
    />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <DataTable
          :value="anexos"
          :paginator="true"
          :rows="5"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} anexos."
          responsiveLayout="scroll"
        >
          <template #empty>
            <div class="p-text-center">Nenhum anexo encontrado...</div>
          </template>
          <Column field="nome" header="Nome"></Column>
          <Column field="url" header="Anexo">
            <template #body="slotProps">
              <Button
                label="DOWNLOAD"
                class="p-button-raised p-button-success"
                icon="pi pi-download"
                v-tooltip.top="'CLIQUE PARA BAIXAR/VISUALIZAR'"
                @click="showAnexo(slotProps.data.url)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <template #footer> </template>
  </Dialog>
  <!-- FIM MODAL DE ANEXOS -->
</template>

<script>
import { FilterMatchMode } from "primevue/api";

import ColumsService from "@/service/Colums/ColumsService";
import RegistroService from "@/service/Registro/RegistroService";
import DatatableService from "@/service/Datatable/DatatableService";
import AnexoService from "@/service/Anexo/AnexoService";

//import { tableexport } from "tableexport";

import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  components: {},
  data() {
    return {
      datatable: {
        id: this.$route.query.dt,
        nome: this.$route.query.name,
      },
      anexo: {
        id: null,
        url: null,
        nome: null,
        identificador: null,
        dt_atualizacao: null,
        registro: {
          id: null,
        },
      },
      anexos: null,
      selectedAnexo: null,
      filters: {},
      columns: [],
      dados: [],
      linha: 0,
      dialogAnexo: false,
      responsaveis: null,
    };
  },
  columsService: null,
  registroService: null,
  datatableService: null,
  anexoService: null,
  created() {
    this.initFilters();
    this.columsService = new ColumsService();
    this.registroService = new RegistroService();
    this.datatableService = new DatatableService();
    this.anexoService = new AnexoService();
  },
  mounted() {
    this.searchColums();
    this.searchRegistro();
    this.findByUsuarioByDatableId(this.datatable.id);
  },
  methods: {
    /*OK ESTA MONTANDO A ESTRUTURA DAS COLUNAS*/
    searchColums() {
      this.columsService
        .findByDatatableId(this.datatable.id)
        .then((data) => {
          data.forEach((element) => {
            this.columns.push({
              header: element.nome.toUpperCase(),
              field: element.nome,
              type: element.tipo,
              nameDatatable: element.datatable.nome.toUpperCase(),
            });
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    // RESPONSAVEL POR MONTAR A ESTRUTURA DO JSON. DEPENDEN DO METORO SEACHCOLUMS() PARA MONTAR O JSON E NECESSARIO A ESTRUTURA DAS COLUNAS
    searchRegistro() {
      this.registroService
        .findByDatatableIdAndVisible(this.datatable.id)
        .then((data) => {
          let paramsData = [];
          for (var i = 0; i < data.length; i++) {
            if (data[i].colums.tipo === "UPLOAD") {
              var texto = "";
              texto =
                "{" +
                '"' +
                data[i].colums.nome +
                '"' +
                ":" +
                '"' +
                data[i].id +
                '",' +
                '"linha":' +
                data[i].ref +
                "," +
                '"coluna":' +
                data[i].colums.id +
                "}";
              //console.log("campo de anexo");
            } else {
              texto =
                "{" +
                '"' +
                data[i].colums.nome +
                '"' +
                ":" +
                '"' +
                data[i].descricao +
                '",' +
                '"linha":' +
                data[i].ref +
                "," +
                '"id":' +
                data[i].id +
                "}";
              //console.log("Não é campo de anexo");
            }
            //console.log(texto)
            var objeto = JSON.parse(texto);
            paramsData.push(objeto);
          }

          let quantRegistros = paramsData.length / this.columns.length;
          //console.log(quantRegistros);
          let agrupados = [];
          //GERANDO A QUANTIDADE DE LINHAS DO DATATABLE
          for (let i = 1; i <= quantRegistros; i++) {
            agrupados = [];

            //DESCOBRINDO QUAIS REGISTRO SÃO DA MESMA LINHA
            paramsData.forEach((element) => {
              if (element.linha === i) {
                agrupados.push(element);
              }
            });

            let obj = this.formatGroups(agrupados);
            //console.log(obj)
            this.dados.push(obj);
          }
          //console.log(this.dados);
        })
        .catch((error) => {
          //console.log(error);
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findByUsuarioByDatableId() {
      this.datatableService
        .findByUsuarioByDatableId(this.datatable.id)
        .then((data) => {
          this.responsaveis = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    openAnexo(registro) {
      this.selectedAnexo = registro;
      this.findAnexoByRegistroId(registro);
      this.dialogAnexo = true;
    },
    showAnexo(param) {
      window.open(param, "blank");
    },
    exportCSV() {
      this.$refs.dt.exportCSV(`${this.datatable.nome}.csv`);
    },
    exportPDF() {
      const doc = new jsPDF("landscape");
      doc.addImage(
        "../assets/layout/images/sespa/brasaoEstadoRelatorio.png",
        "JPEG",
        74,
        23,
        150,
        180
      );

      //CABEÇALHO INICIO
      doc.addImage(
        "../assets/layout/images/sespa/brasaoSespa.png",
        "JPEG",
        126,
        10,
        53,
        20 //altura
      );
      doc
        .setFont("helvetica", "bold")
        .setFontSize(10)
        .text(this.datatable.nome.toUpperCase(), 150, 40, null, null, "center");

      doc.autoTable({
        theme: "plain",
        html: ".p-datatable-table",
        startY: 50,
      });
      //CABEÇALHO FIM
      doc.save(`${this.datatable.nome}.pdf`);

      //console.log(this.dados);
    },
    formatGroups(param) {
      //MAXIMO DE 13 COLUNAS COMO PARAMETRO
      return Object.assign(
        {},
        param[0],
        param[1],
        param[2],
        param[3],
        param[4],
        param[5],
        param[6],
        param[7],
        param[8],
        param[9],
        param[10],
        param[11],
        param[12]
      );
    },
    showGropsDatatable(data) {
      this.$refs.op.toggle(event);
      this.permissao = data;
    },
    findAnexoByRegistroId(idRegistro) {
      this.anexoService
        .findByRegistroId(idRegistro)
        .then((data) => {
          this.anexos = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
.myBox {
  border: none;
  height: 80px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-true {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-false {
    background: #ffcdd2;
    color: #c63737;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>