import jwt_decode from "jwt-decode";

export default class Token {

    verificarTokenExistente() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        //console.log(token)
        if (token === null || token === "") {
            // console.log('não tem token');
            return false;
        } else {
            //  console.log('Tem token');
            return true;
        }
    }
    verificarTokenExpirado() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        let decodeToken = jwt_decode(token.access_token);

        let d1 = new Date(Date.now());
        let d2 = new Date(decodeToken.exp * 1000);

        if (d1 >= d2) {
            // console.log("Expirado");
            return true;
        } else {
            // console.log("Não Expirado");
            return false;
        }
    }
    limparAccessToken() {
        sessionStorage.removeItem('token');
    }
    armazenaToken(token) {
        sessionStorage.setItem('token', JSON.stringify(token));
    }
    jwtDecode(access_token) {
        let payLoad = jwt_decode(access_token);
        return payLoad;
    }
    tokenParams() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        return token;
    }
    payLoad() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        return jwt_decode(token.access_token);
    }

}