<template>
  <MenuExterno titulo="DESPESAS ORÇAMENTÁRIAS" />
  <div class="p-d-flex p-ai-center p-jc-center p-mt-4">
    <div v-if="submitted === true" class="progress">
      <ProgressSpinner style="width:150px;height:150px" strokeWidth="3" fill="#EEEEEE" animationDuration=".6s" />
    </div>
    <Card style="width: 40rem; margin-bottom: 2em;">
      <template #content>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-7">
            <label class="p-mr-2">Órgão</label>
            <InputText id="username" type="text" v-model="orgao" disabled />
          </div>
          <div class="p-field p-col-4">
            <label class="p-mr-2">Ano</label>
            <div class="p-inputgroup">
              <InputNumber inputId="minmax-buttons" v-model="ano" mode="decimal" :min="minYear" :max="maxYear"
                :useGrouping="false" />
              <Button type="button" icon="pi pi-search" label="   " @click="consultarDespesasOrcamentarias()" />
            </div>
          </div>
          <small class="p-ml-2"><b>Observação:</b> Somente permitido consultar
            <b>20 anos</b> anteriores a partir do ano atual.</small>
        </div>
      </template>
    </Card>
  </div>

  <RodapeExterno />

  <Dialog header="Consulta Online das Despesas | Secretaria de Saúde do Estado do Pará" v-model:visible="dialogConsulta"
    :style="{ width: '100vw' }" @hide="hideDialog()">
    <small><b>Fonte:</b> <i>SIAFEM</i></small>
    <DataTable dataKey="id" ref="dt" :value="despesas" class="p-datatable-sm" responsiveLayout="scroll" :scrollable="true"
      scrollHeight="450px" v-model:selection="selectedDespesa" :paginator="true" :rows="30"
      :rowsPerPageOptions="[30, 40, 50]" :filters="filters" :lazy="true" :totalRecords="totalRegistros" :loading="loading"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} registros">
      <template #header>
        <div class="
                    table-header
                    p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                  ">
          <h5>SESPA - CONSULTAR DESPESAS ORÇAMENTÁRIAS</h5>
        </div>
      </template>
      <template #empty>
        <p class="p-text-center">Nenhum resultado encontrado...</p>
      </template>
      <Column header="Sigla UG">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeUnidGestora"] }}
          </small>
        </template>
      </Column>
      <Column header="Grupo Despesa">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeGrupoDespesa"] }}
          </small>
        </template>
      </Column>
      <Column header="Nome Mod. Apl. Despesa">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeModAplDespesa"] }}
          </small>
        </template>
      </Column>
      <Column header="Elemento Despesa">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeElementoDespesa"] }}
          </small>
        </template>
      </Column>
      <Column header="Fonte Recurso">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeFonteRecurso"] }}
          </small>
        </template>
      </Column>
      <Column header="Função">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeFuncao"] }}
          </small>
        </template>
      </Column>
      <Column header="Sub Função">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeSubFuncao"] }}
          </small>
        </template>
      </Column>
      <Column header="Programa">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomePrograma"] }}
          </small>
        </template>
      </Column>
      <Column header="Projeto Atividade">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeProjAtividade"] }}
          </small>
        </template>
      </Column>
      <Column header="UO">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["nomeUnidOrcamentaria"] }}
          </small>
        </template>
      </Column>
      <Column header="Dotação Inicial">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalDotacaoInicial"]) }}
          </small>
        </template>
      </Column>
      <Column header="Dotação Atualizada">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalDotacaoReal"]) }}
          </small>
        </template>
      </Column>
      <Column header="Empenhado">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalEmpenhado"]) }}
          </small>
        </template>
      </Column>
      <Column header="Liquidado">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalDespesaRealizda"]) }}
          </small>
        </template>
      </Column>
      <Column header="Pago">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalPago"]) }}
          </small>
        </template>
      </Column>
      <Column header="A Pagar">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalAPagar"]) }}
          </small>
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>

<script>
import ConsultarDespesasService from "@/service/ConsultaDespesas/ConsultarDespesasService";

import MenuExterno from "@/components/externo/MenuExterno.vue";
import RodapeExterno from "@/components/externo/RodapeExterno.vue";


export default {
  components: {
    MenuExterno: MenuExterno,
    RodapeExterno: RodapeExterno,
  },
  data() {
    return {
      minYear: 0,
      maxYear: 0,
      ano: new Date().getFullYear(),
      orgao: "SECRETARIA DE ESTADO DE SAÚDE PUBLICA",
      despesas: null,
      selectedDespesa: null,
      loading: false,
      submitted: false,
      dialogConsulta: false,
    };
  },
  consultarDespesasService: null,
  created() {
    this.minYear = new Date().getFullYear() - 20;
    this.maxYear = new Date().getFullYear();
    this.consultarDespesasService = new ConsultarDespesasService();
  },
  methods: {
    consultarDespesasOrcamentarias() {
      this.loading = true;
      this.submitted = true;
      this.consultarDespesasService
        .consultarDespesasOrcamentarias(this.ano, 1, 30)
        .then((response) => {
          if (response.status === 200) {
            this.totalRegistros =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasOrcamentariasResponse"
              ]["return"]["totalRegistros"];

            this.despesas =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasOrcamentariasResponse"
              ]["return"]["despesasOrcamentarias"];

            //console.log(this.despesas);
            this.loading = false;
            this.submitted = false;
            this.dialogConsulta = true;
          }
        });
    },
    onPage(event) {
      if (event.first === 0) {
        event.first = 1;
      }
      this.loading = true;
      this.consultarDespesasService
        .consultarDespesasOrcamentarias(this.ano, event.first, event.rows)
        .then((response) => {
          if (response.status === 200) {
            this.totalRegistros =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasOrcamentariasResponse"
              ]["return"]["totalRegistros"];

            this.despesas =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasOrcamentariasResponse"
              ]["return"]["despesasOrcamentarias"];

            this.loading = false;
          }
        });
    },
    hideDialog() {
      this.dialogConsulta = false;
      this.minYear = new Date().getFullYear() - 20;
      this.maxYear = new Date().getFullYear();
      this.despesas = null;
      this.submitted = false;
      this.loading = false;
    },
    formatCurrency(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.linhaFont {
  font-size: 11.5px;
}

.progress {
  bottom: 2;
  position: absolute;
  width: 100%;
  text-align: center;
  align-items: center;
  z-index: 1;
}
</style>
