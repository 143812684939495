import axios from "axios";
import AuthService from '../Auth/AuthService';

export default class PermissoesService extends AuthService {

    sendEmail(dados) {
        return axios({
            method: 'post',
            url: this.api_url + 'email',
            data: dados,
        });
    }
}
