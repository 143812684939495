<template>
    <div class="layout-footer fixarRodape">
        <span class="footer-text" style="margin-left: 5px">&copy; CTIS /</span>
        <span class="footer-text" style="margin-right: 5px; font-weight: 600">
            SESPA - Secretaria de Saúde do Estado do Pará</span>
    </div>
</template>
  
<script>

export default {
    name: 'rodapeExterno',
    data() {
        return {
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
    },
};
</script>
  
<style lang="scss" scoped>
.layout-footer {
    background-color: #dddddd;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    animation: go-back 3s;
}

.fixarRodape {
    bottom: 1;
    position: absolute;
    width: 100%;
    text-align: center;
}

@keyframes go-back {
    from {
        transform: translateX(500px);
    }
    to {
            transform: translateX(0);
        }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

</style>
  