import axios from "axios";
import AuthService from '../Auth/AuthService';

export default class AnexoService extends AuthService {

    findAll() {
        return axios({
            method: 'get',
            url: this.api_url + 'anexo',
            withCredentials: true,

        }).then(res => res.data);
    }
    findByRegistroId(id) {
        return axios({
            method: 'get',
            url: this.api_url + 'anexo/registro/' + id,
            withCredentials: true,

        }).then(res => res.data);
    }
    create(dados) {
        return axios({
            method: 'post',
            url: this.api_url + 'anexo',
            data: dados,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    update(codigo, data) {
        return axios({
            method: 'put',
            url: this.api_url + 'anexo/' + codigo,
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    delete(codigo) {
        return axios({
            method: 'delete',
            url: this.api_url + 'anexo/' + codigo,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
}
