<template>
    <MenuExterno titulo="LINKS DE ACESSO" />
    <div class="p-d-flex p-ai-center p-jc-center">
        <div class="full-body p-mt-5">
            <div class="card body">
                <DataTable ref="dt" :value="links" class="p-datatable-sm" dataKey="id" :filters="filters2" :paginator="true"
                    :rows="10" :rowsPerPageOptions="[10, 20, 50]"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} links.">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5></h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters2['global'].value" placeholder="Pesquisar..." />
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        <div class="p-text-center">Nenhum resultado encontrado...</div>
                    </template>
                    <Column style="width: 20%">
                        <template #body="">
                            <span class="p-column-title"></span>
                            <img alt="logo" src="../../assets/Brasao.png" height="40" class="p-mr-4" />
                        </template>
                    </Column>
                    <Column field="nome" style="width: 70%">
                        <template #body="slotProps">
                            <div class="product-name">
                                {{ slotProps.data.nome.toUpperCase() }}
                            </div>
                        </template>
                    </Column>
                    <Column style="width: 10%">
                        <template #body="slotProps">
                            <Button label="Acessar" class="p-button-lg p-button-raised p-button-help"
                                @click="showLink(slotProps.data.link)" v-tooltip.top="'CLIQUE PARA ABRIR A VISUALIZAÇÃO'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <RodapeExterno />
</template>
  
<script>

import LinkService from "@/service/Links/LinkService";

import { FilterMatchMode } from "primevue/api";

import MenuExterno from "@/components/externo/MenuExterno.vue";
import RodapeExterno from "@/components/externo/RodapeExterno.vue";

export default {
    components: {
        MenuExterno: MenuExterno,
        RodapeExterno: RodapeExterno,
    },
    data() {
        return {
            showLinks: false,
            showConsulta: false,
            links: null,
            filters2: {},
        };
    },
    datatableService: null,
    linkService: null,
    created() {
        this.initFilters();
        this.linkService = new LinkService();
    },
    mounted() {
        this.findAllLinks();
    },
    methods: {
        showLink(url) {
            window.open(url, "_blank");
        },
        findAllLinks() {
            this.linkService
                .findAll()
                .then((data) => {
                    this.links = data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        initFilters() {
            this.filters2 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
  
<style lang="scss" scoped>
.body {
    width: 90%;
    margin-left: 5%;

    .product-name {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

@keyframes go-back {
    from {
        transform: translateX(500px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {

      .p-datatable-thead>tr>th,
      .p-datatable-tfoot>tr>td {
        display: none !important;
      }

      .table-header {
        text-align: center;
        h5{
          display: none;
        }

      }
      .p-datatable-tbody>tr>td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        text-align: center;

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
      }
    }
  }
}
</style>
  