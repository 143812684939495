import axios from "axios";
import Config from '../Auth/Config';


export default class AuthService extends Config {

    login(authentication) {
        return axios({
            method: 'post',
            url: this._api_url + 'oauth/token',
            withCredentials: true,
            data: 'username=' + authentication.username + '&password=' + authentication.password + '&grant_type=password',
            headers: {
                'Authorization': 'Basic c2VzcGF3ZWI6IyQzJHBAY3QxJCM=',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
    logout() {
        let token = sessionStorage.getItem('token');
        token = JSON.parse(token);
        return axios({
            method: 'delete',
            url: this._api_url + 'tokens/revoke',
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + token.access_token
            },
        });
    }
    token() {
        let token = sessionStorage.getItem("token");
        token = JSON.parse(token);
        return token.access_token;
    }
    obterNovoAccessToken() {
        return axios({
            method: 'post',
            url: this._api_url + 'oauth/token',
            withCredentials: true,
            data: 'grant_type=refresh_token',
            headers: {
                'Authorization': 'Basic c2VzcGF3ZWI6IyQzJHBAY3QxJCM=',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    }
}
