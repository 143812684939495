import axios from "axios";
import AuthService from '../Auth/AuthService';

export default class DatatableService extends AuthService {

    consultarDespesasOrcamentarias(ano, pagina, registrosPorPagina) {
        return axios({
            method: 'get',
            url: this.api_url + 'consultar-despesas/' + ano + '/' + pagina + '/' + registrosPorPagina,
            withCredentials: true,
        }).then(res => res);
    }

    consultarDespesasPorEmpenho(data) {
        return axios({
            method: 'post',
            url: this.api_url + 'consultar-despesas',
            data: data,
            withCredentials: true,
        }).then(res => res);
    }

    consultarElementosDeDespesas(ano, unidadeGestora) {
        return axios({
            method: 'get',
            url: this.api_url + 'consultar-despesas/' + ano + "/" + unidadeGestora,
            withCredentials: true,
        }).then(res => res);
    }

    consultarDetalhesNotaEmpenho(numeroEmpenho) {
        return axios({
            method: 'get',
            url: this.api_url + 'consultar-despesas/' + numeroEmpenho,
            withCredentials: true,
        }).then(res => res);
    }   
}