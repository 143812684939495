import axios from "axios";
import AuthService from '../Auth/AuthService';

export default class EditalService extends AuthService {

    findAll() {
        return axios({
            method: 'get',
            url: this.api_url + 'edital',
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }

    findByVisibilidadeAndStatus(visibilidade,status) {
        return axios({
            method: 'get',
            url: this.api_url + 'edital/' + visibilidade + '/'+ status,
            withCredentials: true,
        }).then(res => res.data);
    }
    create(data) {
        return axios({
            method: 'post',
            url: this.api_url + 'edital',
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    update(id, data) {
        return axios({
            method: 'put',
            url: this.api_url + 'edital/' + id,
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    delete(id) {
        return axios({
            method: 'delete',
            url: this.api_url + 'edital/' + id,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }

    /*ATIVAR E DESATIVAR STATUS PARA ENCERRADO OU ABERTO */
    updateStatus(id, dados) {
        return axios({
        method: 'put',
        url: this.api_url + 'edital/' + id + '/status',
        withCredentials: true,
        data: dados,
        headers: {
            'Authorization': 'Bearer ' + this.token(),
            'Content-Type': 'application/json'
        }
        });
    }
    /*ATIVAR E DESATIVAR VISIBILIDADE PARA VISIVÉL E NÃO VISIVÉL */
    updateVisibilidade(id, dados) {
        return axios({
        method: 'put',
        url: this.api_url + 'edital/' + id + '/visibilidade',
        withCredentials: true,
        data: dados,
        headers: {
            'Authorization': 'Bearer ' + this.token(),
            'Content-Type': 'application/json'
        }
        });
    }
}
