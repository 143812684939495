<template>
  <div class="p-grid full">
    <!-- LOGIN -->
    <div class="p-col-12 p-lg-3 fullScreen lg">
      <div class="p-shadow-4 formlogin login">
        <!-- TOPO LOGIN -->
        <div class="headerForm">
          <div class="p-d-flex p-ai-center p-jc-center">
            <img
              src="../assets/logoCtis.svg"
              alt="Logo Ctis"
              class="responsive"
            />
          </div>
        </div>
        <!-- FIM TOPO LOGIN -->

        <!-- BODY LOGIN -->
        <div class="bodyForm">
          <div class="p-d-flex p-ai-center p-jc-center p-fluid p-grid">
            <div class="p-field p-col-8 p-md-7 p-shadow-4 formulario">
              <div class="p-d-flex p-ai-center p-jc-center p-mb-6">
                <img
                  src="../assets/Brasao.png"
                  alt="Logo Sespa"
                  class="responsive"
                />
              </div>
              <form
                @submit.prevent="authenticated(!v$.authentication.$invalid)"
              >
                <InputText
                  id="username"
                  v-model="v$.authentication.username.$model"
                  v-tooltip.right="'Digite seu login.'"
                  placeholder="DIGITE SEU LOGIN"
                  class="p-mb-2"
                  :class="{
                    'p-invalid':
                      submitted && v$.authentication.username.$invalid,
                  }"
                  autofocus
                />
                <Password
                  id="password"
                  v-model="v$.authentication.password.$model"
                  v-tooltip.right="'Digite sua senha.'"
                  placeholder="DIGITE SUA SENHA"
                  toggleMask
                  :feedback="false"
                  :class="{
                    'p-invalid':
                      submitted && v$.authentication.password.$invalid,
                  }"
                  class="p-mb-3"
                />
                <div class="p-d-flex p-ai-center p-jc-center">
                  <a @click="openRefreshPassword()" class="p-mb-3"
                    >Esqueceu sua senha?</a
                  >
                </div>
                <div class="p-d-flex p-ai-center p-jc-center">
                  <Button
                    label="Acessar"
                    icon="pi pi-sign-in"
                    class="p-col-8 p-button-info p-mb-3"
                    type="submit"
                  ></Button>
                </div>
                <!--
                <div class="p-d-flex p-ai-center p-jc-center">
                  <Button
                    label="Registre-se"
                    icon="pi pi-user-plus"
                    class="p-col-8 p-button-success"
                    @click="openRegister()"
                  ></Button>
                </div>
                -->
              </form>
            </div>
          </div>
        </div>
        <!-- FIM BODY LOGIN -->

        <!-- FOOTER LOGIN-->
        <div class="layout-footer">
          <div class="p-text-left">
            <div class="p-text-center footer">
              <span class="footer-text"
                >&copy; CTIS - Coordenação de Tecnologia em Saúde
              </span>
            </div>
          </div>
        </div>
        <!--FIM FOOTER LOGIN -->
      </div>
    </div>
    <!-- FIM LOGIN -->

    <!-- PAINEL -->
    <div class="p-col-12 p-lg-9 fullScreen pn">
      <div class="p-shadow-4 painel">
        <!--HEADER PAINEL -->
        <div class="headerPainel">
          <div class="p-d-flex p-ai-center p-jc-center"></div>
        </div>
        <!--FIM HEADER PAINEL-->
        <!--BODY PAINEL-->
        <div class="bodyPainel">
          <div class="p-d-flex p-ai-center p-jc-center">
            <div class="p-col-12 p-lg-7">
              <!-- IMAGEM / CARROSEL -->
            </div>
          </div>
        </div>
        <!--FIM BODY PAINEL-->
        <!--FOOTER PAINEL-->
        <div class="footerPainel">
          <div class="layout-footer">
            <div class="p-text-right footer">
              <img src="../assets/Brasao.png" alt="SESPA" width="50" />
              <span class="footer-text"> V 1.1 </span>
            </div>
          </div>
        </div>
        <!--FIM FOOTER PAINEL-->
      </div>
    </div>
    <!-- FIM PAINEL -->
  </div>

  <!-- RECUPERAÇÃO DE SENHA -->
  <Dialog
    header="Recuperação de senha"
    v-model:visible="refreshPasswordDialog"
    @hide="hideDialog()"
    :style="{ width: '450px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="confSenha"
            >Digite um e-mail válido para recuperação.</label
          >
          <span class="p-input-icon-left">
            <i class="pi pi-envelope" />
            <InputText
              id="email"
              type="email"
              v-model="sendEmail.emailTo"
              placeholder="Seu e-mail."
            />
          </span>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="Enviar"
        icon="pi pi-check"
        :disabled="!sendEmail.emailTo"
        @click="restorePassword()"
        class="p-button-raised p-button-info"
      />
    </template>
  </Dialog>
  <!--FIM RECUPERAÇÃO DE SENHA -->
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import AuthService from "@/service/Auth/AuthService";
import EmailService from "@/service/Email/EmailService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      refreshPasswordDialog: false,
      authentication: {
        username: null,
        password: null,
      },
      sendEmail: {
        emailTo: null,
        subject: "Recuperação de Senha",
        text: null,
      },
    };
  },
  validations() {
    return {
      authentication: {
        username: {
          required,
        },
        password: {
          required,
        },
      },
    };
  },
  authService: null,
  emailService: null,
  created() {
    this.authService = new AuthService();
    this.emailService = new EmailService();
    if (sessionStorage.getItem("token")) {
      this.$router.push("/system");
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    async authenticated(isFormValidLogin) {
      this.submitted = true;
      if (!isFormValidLogin) {
        return;
      }
      this.authService
        .login(this.authentication)
        .then((response) => {
          this.$Token.armazenaToken(response.data);
          //this.authService.armazenaToken(response.data);
          this.$router.push("/system");
        })
        .catch((error) => {
          if (
            error.response.status === 400 &&
            error.response.data.error_description ===
              "Usu&aacute;rio desabilitado"
          ) {
            this.$toast.add({
              severity: "warn",
              summary: "Atenção.",
              detail:
                "Usuário que esta tentando logar encontra-se inativo. Entre em contato com ADMINISTRADOR para ativar.",
            });
          } else if (
            error.response.status === 400 &&
            error.response.data.error_description ===
              "Usu&aacute;rio inexistente ou senha inv&aacute;lida"
          ) {
            this.$toast.add({
              severity: "error",
              summary: "Atenção.",
              detail: "Usuário e senha incorretos, tente novamente.",
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Atenção.",
              detail: "Error entre com contato com o desenvolvedor.",
            });
          }
        });
    },
    async restorePassword() {
      this.submitted = true;
      this.sendEmail.text =
        "<p style='text-align: justify;'>" +
        `<b> Prezado(a) senhor(a),</b> informamos que foi solicitado a recuperação de senha através deste e-mail pelo usuário ${this.sendEmail.emailTo}` +
        " valendo ressaltar que é de carácter integro e sigiloso que informações alteradas é de responsabilidade do próprio" +
        " usuário, caso você não seja o portador deste e-mail o ignore.</p>" +
        `<b>Segue abaixo o link para recuperação de senha:</b><br><br> <a href='http://172.32.1.11:8080/transparencia/#/refreshPassword?email=${this.sendEmail.emailTo}'>` +
        "<button style='background: #069cc2; border-radius: 3px; padding: 10px; cursor: pointer; color: #fff; border: none; font-size: 16px;'>Link de Recuperação</button>" +
        "</a><h4>Atenciosamente, Ctis.</h4>";
      this.emailService
        .sendEmail(this.sendEmail)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Recuperação de senha.",
              detail: `O link de recuperação de senha foi enviado para e-mail ${this.sendEmail.emailTo}.`,
            });
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    openRegister() {
      this.$router.push("/register");
    },
    openRefreshPassword() {
      this.refreshPasswordDialog = true;
    },
    hideDialog() {
      this.sendEmail.emailTo = null;
      this.refreshPasswordDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.lg {
  .headerForm {
    img {
      margin-top: 3rem;
    }
  }
}

.fullScreen {
  height: 99vh;
  overflow: hidden;
  margin-left: -5px;
}
.formlogin {
  height: 99%;
  margin-top: 3px;
  margin-left: 10px;
}
.painel {
  background-image: radial-gradient(#4169e1, #0288d1);
  margin-top: 3px;
  height: 99%;
  margin-left: 10px;
}
.login {
  background-image: url("../assets/fundo.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover*/
}
.headerForm {
  height: 40%;
}
.bodyForm {
  height: 53%;
}
.headerPainel {
  height: 20%;
}
.bodyPainel {
  height: 72.3%;
}
.layout-footer {
  background-color: #dddddd;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.formulario {
  width: 80%;
  padding: 10%;
}
.footer {
  font-size: 12px;
}
.responsive {
  width: 70%;
}
.full {
  margin: 0px auto;
}

@media screen and (max-width: 810px) {
  .pn {
    display: none !important;
  }
  .lg {
    .headerForm {
      img {
        margin-top: 4rem;
      }
    }
  }
  .responsive {
    width: 53%;
  }
}
</style>