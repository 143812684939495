<template>
  <MenuExterno titulo="DESPESAS COM DIÁRIAS" />

  <div class="p-d-flex p-ai-center p-jc-center p-mt-4">
    <div v-if="showProgress === true" class="progress">
      <ProgressSpinner style="width:150px;height:150px" strokeWidth="3" fill="#EEEEEE" animationDuration=".6s" />
    </div>
    <Card style="width: 50rem; margin-bottom: 2em">
      <template #content>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <label class="p-mr-2">Ano</label>
            <div class="p-inputgroup">
              <InputNumber inputId="minmax-buttons" v-model="form.ano" mode="decimal" :min="minYear" :max="maxYear"
                :useGrouping="false" :class="{
                  'p-invalid': submitted && !form.ano,
                }" showButtons />
              <small class="p-invalid" v-if="submitted && !form.ano">Este campo é obrigatorio.</small>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label class="p-mr-2">Unidade Gestora</label>
            <Dropdown v-model="form.unidGestora" :options="unidadeGestora" optionLabel="nome" optionValue="id" :class="{
              'p-invalid': submitted && !form.unidGestora,
            }" />
            <small class="p-invalid" v-if="submitted && !form.unidGestora">Este campo é obrigatorio.</small>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <label class="p-mr-2">Beneficiário (Opcional)</label>
            <InputText id="username" type="text" v-model="form.nome" />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label class="p-mr-2">CPF/CNPJ (Opcional). Obs: Sem pontos ou traços.</label>
            <InputText id="username" type="text" v-model="form.cpfAndCnpj" />
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label class="p-mr-2">Elemento de Despesa</label>
            <Dropdown v-model="form.elementoDespesa" :options="elementosDespesa" optionLabel="nome" optionValue="id"
              disabled :class="{
                'p-invalid': submitted && !form.elementoDespesa,
              }" />
          </div>
        </div>

        <Fieldset legend="Pesquisa por Periodo" class="p-mt-2">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label class="p-mr-2">Período Inicial. Utilize DDMM</label>
              <InputText id="username" type="text" v-model="form.inicio" />
            </div>
            <div class="p-field p-col-12">
              <label class="p-mr-2">Período Final. Utilize DDMM</label>
              <InputText id="username" type="text" v-model="form.fim" />
            </div>
          </div>
        </Fieldset>
        <small class="p-ml-2"><b>Observação:</b> Somente permitido consultar
          <b>20 anos</b> anteriores a partir do ano atual.</small>
      </template>
      <template #footer>
        <Button icon="pi pi-search" label="Consultar" @click="consultarDespesasEmpenho()" />
        <Button icon="pi pi-times" label="Limpar" class="p-button-secondary" style="margin-left: 0.5em" />
      </template>
    </Card>
  </div>

  <RodapeExterno />

  <Dialog header="Consulta Online das Despesas" v-model:visible="dialogConsulta" :style="{ width: '100vw' }"
    @hide="hideDialog()">
    <small><b>Fonte:</b> <i>SIAFEM</i></small>
    <DataTable dataKey="id" ref="dt" :value="despesas" class="p-datatable-sm" responsiveLayout="scroll" :scrollable="true"
      scrollHeight="450px" v-model:selection="selectedDespesa" :paginator="true" :rows="30"
      :rowsPerPageOptions="[30, 40, 50]" :filters="filters" :lazy="true" :totalRecords="totalRegistros" :loading="loading"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} registros">
      <template #header>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-md-2">
                <div class="p-d-flex p-ai-center p-jc-center">
                  <img src="../../assets/Brasao.png" alt="SESPA" width="150" class="ctis" style="margin-top: 25px" />
                </div>
              </div>
              <div class="p-col-12 p-md-9">
                <label>Ano: {{ form.ano }}</label><br />
                <label>Orgão: SECRETARIA DE ESTADO DE SAUDE PUBLICA </label><br />
                <label>Unidade Gestora: {{ form.unidGestora }} -
                  {{ getUnid(form.unidGestora) }}</label><br />
                <label>Total Empenhado: {{ formatCurrency(totalEmpenhado) }}</label><br />
                <label>Total Pago: {{ formatCurrency(totalPago) }}</label>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <p class="p-text-center">Nenhum resultado encontrado...</p>
      </template>
      <Column header="N° Empenhado">
        <template #body="slotProps">
          <small class="linhaFont">
            <Button :label="slotProps.data['numeroEmpenhoOriginal']" class="p-button-link" @click="
              openDialogConsultarDetalhes(slotProps.data['numeroEmpenho'])
            " />
          </small>
        </template>
      </Column>
      <Column header="Data do Empenho">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ $DateTime.formatDate(slotProps.data["dataEmpenho"]) }}
          </small>
        </template>
      </Column>
      <Column header="CPF/CGC/IG">
        <template #body="slotProps">
          <small class="linhaFont">
            {{
              formatCnpjCpf(slotProps.data["cpfCnpjIgBeneficiario"].toString())
            }}
          </small>
        </template>
      </Column>
      <Column header="Beneficiario">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ slotProps.data["beneficiario"] }}
          </small>
        </template>
      </Column>
      <Column header="Empenhado">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["valorEmpenho"]) }}
          </small>
        </template>
      </Column>
      <Column header="Anulação">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["valorTotalAnulacao"]) }}
          </small>
        </template>
      </Column>
      <Column header="Reforço">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["valorTotalReforco"]) }}
          </small>
        </template>
      </Column>
      <Column header="Empenhado Final">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["valorTotalEmpenho"]) }}
          </small>
        </template>
      </Column>
      <Column header="Pago">
        <template #body="slotProps">
          <small class="linhaFont">
            {{ formatCurrency(slotProps.data["totalPagamento"]) }}
          </small>
        </template>
      </Column>

      <ColumnGroup type="footer">
        <Row>
          <Column footer="Total:" :colspan="8" footerStyle="text-align:right" />
          <Column :footer="formatCurrency(totalPago)"> </Column>
        </Row>
      </ColumnGroup>
    </DataTable>
  </Dialog>

  <Dialog :header="title" v-model:visible="dialogConsultarDetalhes" :style="{ width: '80vw' }">
    <Card>
      <template #content>
        <div class="field col-12">
          <label for="ano">Ano: </label>
          <b> {{ detalheDespesa.ano }}.</b>
        </div>
        <div class="field col-12">
          <label for="tpPesquisa">Tipo de Pesquisa:</label>
          <b></b>
        </div>
        <div class="field col-12">
          <label for="orgao">Orgão: </label>
          <b> {{ detalheDespesa.descricaoOrgao }}.</b>
        </div>
        <div class="field col-12">
          <label for="undGestora">Unidade Gestora: </label>
          <b>{{ detalheDespesa.descricaoUnidadeGestora }}.</b>
        </div>
        <hr />
        <div class="field col-12">
          <label for="numero">Número: </label>
          <b> {{ detalheDespesa.numeroEmpenho }}.</b>
        </div>
        <div class="field col-12">
          <label for="modalidade">Modalidade: </label>
          <b>{{ detalheDespesa.descricaoModalidade }}.</b>
        </div>
        <div class="field col-12">
          <label for="programa">Programa: </label>
          <b>{{ detalheDespesa.nomePrograma }}.</b>
        </div>
        <div class="field col-12">
          <label for="pgAtividade">Projeto Atividade: </label>
          <b>{{ detalheDespesa.nomeProjetoAtividade }}</b>
        </div>
        <div class="field col-12">
          <label for="tpLicitacao">Tipo de Licitação: </label>
          <b> {{ detalheDespesa.descricaoTipoLicitacao }}.</b>
        </div>
        <div class="field col-12">
          <label for="nProcesso">Número do Processo: </label>
          <b>{{ detalheDespesa.numeroProcesso }}.</b>
        </div>
        <div class="field col-12">
          <label for="beneficiario">Beneficiário: </label>
          <b> {{ detalheDespesa.beneficiario }}.</b>
        </div>
        <div class="field col-12">
          <label for="fcProgramatica">Função Programática: </label>
          <b>{{ detalheDespesa.descricaoFuncionalProgramatica }}.</b>
        </div>
        <div class="field col-12">
          <label for="ftRecurso">Fonte de Recurso: </label>
          <b>{{ detalheDespesa.descricaoFonteRecurso }}.</b>
        </div>
        <div class="field col-12">
          <label for="funcao">Função: </label>
          <b>{{ detalheDespesa.itensNotaEmpenho.funcao }} -
            {{ detalheDespesa.itensNotaEmpenho.nomeFuncao }}.</b>
        </div>
        <div class="field col-12">
          <label for="sibFuncao">Sub-Função: </label>
          <b>
            {{ detalheDespesa.itensNotaEmpenho.subfuncao }} -
            {{ detalheDespesa.itensNotaEmpenho.nomeSubfuncao }}.</b>
        </div>
        <div class="field col-12">
          <label for="elDespesa">Elemento de Despesa: </label>
          <b>{{ detalheDespesa.idElementoDespesa }} -
            {{ detalheDespesa.descricaoElementoDespesa }}.</b>
        </div>
        <div class="field col-12">
          <label for="categoria">Categoria: </label>
          <b>
            {{ detalheDespesa.idCategoriaDespesa }} -
            {{ detalheDespesa.nomeCategoriaDespesa }}.</b>
        </div>
        <div class="field col-12">
          <label for="gpDespesa">Grupo de Despesa: </label>
          <b>
            {{ detalheDespesa.idGrupoDespesa }} -
            {{ detalheDespesa.nomeGrupoDespesa }}.</b>
        </div>
        <div class="field col-12">
          <label for="modalidade">Modalidade: </label>
          <b>{{ detalheDespesa.idModAplDespesa }} -
            {{ detalheDespesa.nomeModAplDespesa }}.</b>
        </div>
        <hr />
        <div class="field col-12">
          <DataTable :value="itensNotaEmpenho" class="p-datatable-sm" responsiveLayout="scroll" :scrollable="true">
            <Column field="especificacao" header="Especificação" style="width: 500px"></Column>
            <!--<Column field="year" header="Unidade de Medida"></Column>-->
            <Column field="quantidade" header="Quantidade">
              <template #body="slotProps">
                <small class="linhaFont">
                  {{ slotProps.data["quantidade"] }}
                </small>
              </template>
            </Column>
            <Column field="precoUnitario" header="Valor Unitario">
              <template #body="slotProps">
                <small class="linhaFont">
                  {{ slotProps.data["precoUnitario"] }}
                </small>
              </template>
            </Column>
            <Column field="precoTotal" header="Valor Total">
              <template #body="slotProps">
                <small class="linhaFont">
                  {{ formatCurrency(slotProps.data["precoTotal"]) }}
                </small>
              </template>
            </Column>

            <ColumnGroup type="footer">
              <Row>
                <Column footer="Total:" :colspan="3" footerStyle="text-align:right" />
                <Column :footer="calcularTotal()" />
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>
      </template>
    </Card>
  </Dialog>
</template>
  
<script>
import ConsultarDespesasService from "@/service/ConsultaDespesas/ConsultarDespesasService";

import MenuExterno from "@/components/externo/MenuExterno.vue";
import RodapeExterno from "@/components/externo/RodapeExterno.vue";

export default {
  components: {
    MenuExterno: MenuExterno,
    RodapeExterno: RodapeExterno,
  },
  data() {
    return {
      dialogConsulta: false,
      dialogConsultarDetalhes: false,
      minYear: 0,
      maxYear: 0,
      despesas: null,
      detalheDespesa: null,
      itensNotaEmpenho: null,
      submitted: false,
      loading: false,
      elementosDespesa: [
        {
          id: 14,
          nome: "DIARIAS - CIVIL",
        },
      ],
      unidadeGestora: [
        {
          id: 200105,
          nome: "1° REGIONAL DE PROTEÇÃO SOCIAL-BELÉM",
        },
        {
          id: 200107,
          nome: "2° REGIONAL DE PROTEÇÃO SOCIAL-SANTA IZABEL",
        },
        {
          id: 200104,
          nome: "3° REGIONAL DE PROTEÇÃO SOCIAL-CASTANHAL",
        },

        {
          id: 200108,
          nome: "4° REGIONAL DE PROTEÇÃO SOCIAL-CAPANEMA",
        },

        {
          id: 200114,
          nome: "5° REGIONAL DE PROTEÇÃO SOCIAL-SÃO MIGUEL DO GUAMA",
        },

        {
          id: 200110,
          nome: "6° REGIONAL DE PROTEÇÃO SOCIAL-BARCARENA",
        },

        {
          id: 200113,
          nome: "7° REGIONAL DE PROTEÇÃO SOCIAL-R. DAS ILHAS",
        },

        {
          id: 200111,
          nome: "8° REGIONAL DE PROTEÇÃO SOCIAL-BREVES",
        },

        {
          id: 200103,
          nome: "9° REGIONAL DE PROTEÇÃO SOCIAL-SANTAREM",
        },

        {
          id: 200109,
          nome: "10° REGIONAL DE PROTEÇÃO SOCIAL-ALTAMIRA",
        },

        {
          id: 200102,
          nome: "11° REGIONAL DE PROTEÇÃO SOCIAL-MARABA",
        },

        {
          id: 200106,
          nome: "12° REGIONAL DE PROTEÇÃO SOCIAL-CONCEIÇAO DO ARAGUAIA",
        },

        {
          id: 200112,
          nome: "13° REGIONAL DE PROTEÇÃO SOCIAL-CAMETA",
        },

        {
          id: 200121,
          nome: "ESCOLA TECNICA DO SUS",
        },

        {
          id: 200116,
          nome: "HOSPITAL REGIONAL DE CAMETA",
        },

        {
          id: 200117,
          nome: "HOSPITAL REGIONAL DE CONCEIÇÃO DE ARAGUAIA",
        },

        {
          id: 200118,
          nome: "HOSPITAL REGIONAL DE SALINOPOLIS",
        },

        {
          id: 200101,
          nome: "SECRETARIA DE ESTADO DE SAUDE PUBLICA",
        },
        {
          id: 200120,
          nome: "LABORATORIO CENTRAL LACEM",
        },
      ],
      form: {
        registroPorPagina: 30,
        pagina: 1,
        ano: new Date().getFullYear(),
        nome: "",
        cpfAndCnpj: "",
        elementoDespesa: "",
        unidGestora: "",
        numeroEmpenho: "",
        inicio: "",
        fim: "",
      },
      title: null,
      itensPesquisa: null,
      totalEmpenhado: "",
      totalPago: "",
      showProgress: false,
    };
  },
  consultarDespesasService: null,
  created() {
    this.minYear = new Date().getFullYear() - 20;
    this.maxYear = new Date().getFullYear();
    this.consultarDespesasService = new ConsultarDespesasService();
  },
  mounted() {
    this.form.elementoDespesa = 14;
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    consultarDespesasEmpenho() {
      this.loading = true;
      this.submitted = true;
      this.showProgress = true;

      if (this.form.unidGestora === "" || !this.form.unidGestora) {
        this.showProgress = false;
        return;
      }
      this.consultarDespesasService
        .consultarDespesasPorEmpenho(this.form)
        .then((response) => {
          if (response.status === 200) {
            //console.log(response.data["env:Envelope"]["env:Body"]["con:consultarDespesasResponse"]["return"]);
            this.totalRegistros =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasResponse"
              ]["return"]["totalRegistros"];
            this.totalEmpenhado =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasResponse"
              ]["return"]["totalEmpenhos"];

            this.totalPago =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasResponse"
              ]["return"]["totalPagamentos"];

            let resDespesa =
              response.data["env:Envelope"]["env:Body"][
              "con:consultarDespesasResponse"
              ]["return"]["notasEmpenhoOriginais"];

            if (this.totalRegistros === 0) {
              this.despesas = [];
              this.submitted = false;
              this.dialogConsulta = true;
              this.loading = false;
              this.showProgress = false;
              return;
            }

            if (resDespesa[1]) {
              this.despesas = resDespesa;
            } else {
              this.despesas = [resDespesa];
            }

            this.submitted = false;
            this.dialogConsulta = true;
            this.loading = false;
            this.showProgress = false;
          }
        });
    },
    consultarElementosDespesa() {
      if (!this.form.ano && !this.form.unidGestora == null) {
        return;
      }
      this.consultarDespesasService
        .consultarElementosDeDespesas(this.form.ano, this.form.unidGestora)
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data["env:Envelope"]["env:Body"][
              "con:buscarElementosDespesaResponse"
              ]["return"]["item"]
            ) {
              this.elementosDespesa =
                response.data["env:Envelope"]["env:Body"][
                "con:buscarElementosDespesaResponse"
                ]["return"]["item"];
            } else {
              this.$toast.add({
                severity: "warn",
                summary: "Alerta!",
                detail: "Nenhum registro encontrado para esta unidade gestora.",
              });
            }
          }
        });
    },
    onPage(event) {
      this.loading = true;
      if (event.first === 0) {
        event.first = 1;
      }

      this.form.registroPorPagina = event.rows;
      this.form.pagina = event.first;

      this.consultarDespesasService
        .consultarDespesasPorEmpenho(this.form, this.form.unidGestora)
        .then((response) => {
          this.totalEmpenhado =
            response.data["env:Envelope"]["env:Body"][
            "con:consultarDespesasResponse"
            ]["return"]["totalEmpenhos"];
          this.totalPago =
            response.data["env:Envelope"]["env:Body"][
            "con:consultarDespesasResponse"
            ]["return"]["totalPagamentos"];
          this.totalRegistros =
            response.data["env:Envelope"]["env:Body"][
            "con:consultarDespesasResponse"
            ]["return"]["totalRegistros"];
          this.despesas =
            response.data["env:Envelope"]["env:Body"][
            "con:consultarDespesasResponse"
            ]["return"]["notasEmpenhoOriginais"];

          this.loading = false;
        });
    },
    formatCnpjCpf(value) {
      //return value;
      const cnpjCpf = value.replace(/\D/g, "");
      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }
      return cnpjCpf.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
    hideDialog() {
      this.dialogConsulta = false;
      this.minYear = new Date().getFullYear() - 20;
      this.maxYear = new Date().getFullYear();
      this.despesas = null;
      this.submitted = false;
      this.loading = false;
      this.form = {
        registroPorPagina: 30,
        pagina: 1,
        ano: new Date().getFullYear(),
        nome: "",
        cpfAndCnpj: "",
        elementoDespesa: 14,
        unidGestora: "",
        numeroEmpenho: "",
        inicio: "",
        fim: "",
      };
      this.itensPesquisa = null;
      this.totalEmpenhado = "";
      this.totalPago = "";
    },
    openDialogConsultarDetalhes(empenho) {
      this.consultarDespesasService
        .consultarDetalhesNotaEmpenho(empenho)
        .then((response) => {
          if (response.status === 200) {
            this.detalheDespesa =
              response.data["env:Envelope"]["env:Body"][
              "con:buscarDetalhesNotaEmpenhoResponse"
              ]["return"];

            if (!this.detalheDespesa.itensNotaEmpenho[1]) {
              this.itensNotaEmpenho = [this.detalheDespesa.itensNotaEmpenho];
            } else {
              this.itensNotaEmpenho = this.detalheDespesa.itensNotaEmpenho;
            }
            this.title = `Consulta Online Despesa - ${this.detalheDespesa.numeroEmpenho}`;
            this.dialogConsultarDetalhes = true;
          }
        });
    },
    getUnid(param) {
      let nomeUnid = this.unidadeGestora.filter((unid) => param == unid.id);
      return nomeUnid[0].nome;
    },
    calcularTotal() {
      let total = 0;
      this.itensNotaEmpenho.forEach((element) => {
        total += element.precoTotal;
      });
      return this.formatCurrency(total);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.linhaFont {
  font-size: 11.5px;
}

.p-invalid {
  color: red;
}

.progress {
  bottom: 2;
  position: absolute;
  width: 100%;
  text-align: center;
  align-items: center;
  z-index: 1;
}
</style>
  