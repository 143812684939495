<template>
    <div class="layout-header">
        <Menubar :model="items" style="font-weight: 600" class="layout-footer">
            <template #start>
                <img alt="logo" src="../../assets/Brasao.png" height="50" class="p-mr-4 logo-menu" />
            </template>
            <template #end>
                <div class="texto">{{ titulo }}</div>
                <!--<div class="texto">PORTAL DA TRANSPARÊNCIA</div>-->
            </template>
        </Menubar>
    </div>
</template>
  
<script>
export default {
    name: 'rodapeExterno',
    props: {
        titulo: String,
    },
    data() {
        return {
            items: [
                {
                    label: "TRANSPARÊNCIA PÚBLICA",
                    command: () => {
                        this.$router.push("/");
                    },
                },
                {
                    label: "LINKS DE ACESSO",
                    command: () => {
                        this.$router.push("/links-externo");
                    },
                },
                {
                    label: "DESPESAS POR EMPENHO",
                    command: () => {
                        this.$router.push("/despesas-empenho");
                    },
                },
                {
                    label: "DESPESAS | EXECUÇÃO ORÇAMENTÁRIA",
                    command: () => {
                        this.$router.push("/despesas-orcamentaria");
                    },
                },
                {
                    label: "DESPESAS COM DIÁRIAS",
                    command: () => {
                        this.$router.push("/despesas-diarias");
                    },
                },
                {
                    label: "CHAMAMENTO PÚBLICO",
                    items: [
                        {
                            label: 'CHAMAMENTOS ABERTOS',
                            icon: 'pi pi-fw pi-plus-circle',
                            command: () => {
                                this.$router.push("/chamamento-aberto");
                            }
                        },
                        {
                            label: 'CHAMAMENTOS ENCERRADOS',
                            icon: 'pi pi-fw pi-minus-circle',
                            command: () => {
                                this.$router.push("/chamamento-fechado");
                            }
                        }
                    ]
                },
            ],

        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
    },

};
</script>
  
<style lang="scss" scoped>
.layout-footer {
    background-color: #dddddd;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    animation: go-back 3s;
}

.texto {
    font-size: 1.5rem;
    font-weight: 700;
}

@media screen and (max-width: 576px) {
    .logo-menu {
        display: none;
    }

    .texto {
        font-size: 1.3rem;
        font-weight: 700;
    }
}

@keyframes go-back {
    from {
        transform: translateX(500px);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>
  ;