import axios from "axios";
import AuthService from '../Auth/AuthService';

export default class RegistroService extends AuthService {

    findAll() {
        return axios({
            method: 'get',
            url: this.api_url + 'registros',
            withCredentials: true,

        }).then(res => res.data);
    }
    findByDatatableId(codigo) {
        return axios({
            method: 'get',
            url: this.api_url + 'registros/datatable/' + codigo,
            withCredentials: true,
        }).then(res => res.data);
    }
    findByDatatableIdAndVisible(codigo) {
        return axios({
            method: 'get',
            url: this.api_url + 'registros/datatable/' + codigo + '/true',
            withCredentials: true,
        }).then(res => res.data);
    }
    findByDatatableByRef(linha, datatable) {
        return axios({
            method: 'get',
            url: this.api_url + 'registros/' + linha + '/datatable/' + datatable,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }
    updateStatus(id, dados) {
        return axios({
            method: 'put',
            url: this.api_url + 'registros/' + id + '/status',
            withCredentials: true,
            data: dados,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
                'Content-Type': 'application/json'
            }
        });
    }
    create(dados) {
        return axios({
            method: 'post',
            url: this.api_url + 'registros',
            data: dados,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    update(codigo, data) {
        return axios({
            method: 'put',
            url: this.api_url + 'registros/' + codigo,
            data: data,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
    delete(idDatatable, linha) {
        return axios({
            method: 'delete',
            url: this.api_url + 'registros/' + idDatatable + '/' + linha,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        });
    }
}
