<template>
    <MenuExterno titulo="EDITAIS ENCERRADOS" />
    <div class="p-d-flex p-ai-center p-jc-center">
        <div class="full-body p-mt-5">
            <!--INICIO DATATABLE-->
            <div class="card body">
                <DataTable ref="dt" :value="editais" class="p-datatable-sm" dataKey="id" :filters="filters"
                    :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} editais.">
                    <template #header>
                        <div class="
                                table-header
                                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
                                ">
                            <h5>CHAMAMENTO PÚBLICO - EDITAIS ENCERRADOS</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Pesquisar..." />
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        <div class="p-text-center">Nenhum resultado encontrado...</div>
                    </template>
                    <Column header="N° do Edital" field="nedital">
                        <template #body="slotProps">
                            <div class="product-name">
                                {{ slotProps.data.nedital }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Hospital" field="nhospital">
                        <template #body="slotProps">
                            <div class="product-name">
                                {{ slotProps.data.nhospital }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Município" field="municipio">
                        <template #body="slotProps">
                            <div class="product-name">
                                {{ slotProps.data.municipio }}
                            </div>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button label="Acessar" class="p-button-lg p-button-raised p-button-danger p-mr-2"
                                @click="showDialogRegistro(slotProps.data)"
                                v-tooltip.top="'CLIQUE PARA ABRIR A VISUALIZAÇÃO'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <!--FIM DATATABLE-->
        </div>
    </div>
    <RodapeExterno />
    <!--MODAL EXIBIR REGISTROS-->
    <Dialog v-model:visible="registroDialog" :header="titleDialog" :style="{ width: '1200px' }" :modal="true"
        @hide="hideDialogs()">
        <DataTable 
            ref="dt" 
            :value="registros" 
            class="p-datatable-sm" 
            v-model:selection="selectedRegistro" 
            dataKey="id"
            :paginator="true" 
            :rows="10" 
            :rowsPerPageOptions="[10, 20, 50]" 
            :filters="filters2"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} registros">
            <template #header>
                <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                    <h5>{{ edital.nedital }}</h5>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters2['global'].value" placeholder="Pesquisar..." />
                    </span>
                </div>
            </template>
            <template #empty>
                <div class="p-text-center">Nenhum resultado encontrado...</div>
            </template>
            <Column header="Data" field="publicacao" :sortable="true">
                <template #body="slotProps">
                    {{ $DateTime.formatDate(slotProps.data.publicacao) }}
                </template>
            </Column>
            <Column header="Descrição" field="descricao" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.descricao }}
                </template>
            </Column>
            <Column header="Anexo">
                <template #body="slotProps">
                    <Button label="ANEXO" class="p-button-raised p-button-secondary" icon="fa-solid fa-folder"
                        @click="showAnexo(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </Dialog>
    <!--FIM MODAL EXIBIR REGISTROS-->

    <!-- MODAL DE ANEXOS -->
    <Dialog v-model:visible="dialogAnexo" :header="titleDialog" :style="{ width: '650px' }" :modal="true">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <DataTable :value="anexos" :paginator="true" :rows="5"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} anexos."
                    responsiveLayout="scroll">
                    <template #empty>
                        <div class="p-text-center">Nenhum anexo encontrado...</div>
                    </template>
                    <Column field="nome" header="Nome"></Column>
                    <Column field="url" header="Anexo">
                        <template #body="slotProps">
                            <Button label="DOWNLOAD" class="p-button-raised p-button-success" icon="pi pi-download"
                                v-tooltip.top="'CLIQUE PARA BAIXAR/VISUALIZAR'" @click="openAnexo(slotProps.data.url)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <template #footer> </template>
    </Dialog>
    <!-- FIM MODAL DE ANEXOS -->
</template>
    
<script>

import { FilterMatchMode } from "primevue/api";

import EditalService from "@/service/Edital/EditalService";
import RegistroChamamentoService from "@/service/RegistroChamamento/RegistroChamamentoService";
import AnexoChamamentoService from "@/service/AnexoChamamento/AnexoChamamentoService";

import MenuExterno from "@/components/externo/MenuExterno.vue";
import RodapeExterno from "@/components/externo/RodapeExterno.vue";

export default {
    components: {
        MenuExterno: MenuExterno,
        RodapeExterno: RodapeExterno,
    },
    data() {
        return {
            filters: {},
            filters2: {},
            registroDialog: false,
            registros: null,
            registro: null,
            items: [
                {
                    label: "INÍCIO ",
                    to: "/",
                    icon: "pi pi-home",
                },
            ],
            editais: null,
            edital: {
                id: null,
                municipio: null,
                visibilidade: false,
                status: true,
                nhospital: null,
                nedital: null,
                npae: null
            },
            title: null,
            dialogAnexo: false,
            anexo: {
                id: null,
                url: null,
                nome: null,
                identificador: null,
                dt_atualizacao: null,
                registro: {
                    id: null,
                },
            },
        };
    },
    editalService: null,
    registroChamamentoService: null,
    anexoChamamentoService: null,
    created() {
        this.initFilters();
        this.editalService = new EditalService();
        this.registroChamamentoService = new RegistroChamamentoService();
        this.anexoChamamentoService = new AnexoChamamentoService();
    },
    mounted() {
        this.findAll();
    },
    methods: {
        findAll() {
            this.editalService
                .findByVisibilidadeAndStatus(true, false)
                .then((data) => {
                    this.editais = data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
            this.filters2 = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        showDialogRegistro(edital) {
            this.edital = edital;
            this.registroChamamentoService
                .findRegistroChamamentoById(this.edital.id)
                .then((data) => {
                    this.registros = data;
                    this.titleDialog = `${this.edital.nedital} | ${this.edital.nhospital}`
                    this.registroDialog = true;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        showAnexo(registro) {
            this.registro = registro;
            this.titleDialog = "Lista de Anexos"
            this.findAnexoByRegistroId(registro.id);

        },
        findAnexoByRegistroId(idRegistro) {
            this.anexoChamamentoService
                .findByRegistroId(idRegistro)
                .then((data) => {
                    this.anexos = data;
                    this.dialogAnexo = true;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        hideDialogs() {
            this.registroDialog = false;
            this.dialogAnexo = false;
        },
        openAnexo(anexo) {
            window.open(anexo, "blank");
        },
    },
};
</script>
    
<style lang="scss" scoped>
.full-body {
    width: 80%;
}


.p-datatable-sm .p-datatable-tbody>tr>td .p-column-title {
    display: none !important;
}
@media screen and (max-width: 40em) {
    .table-header {
        text-align: center;
        h5{
          display: none;
        }

    }
    ::v-deep(.p-datatable) {
        &.p-datatable-sm {
            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }
        .p-datatable-tbody>tr>td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;
                .p-column-title {
                    padding: 0.4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -0.4em 1em -0.4em -0.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                    text-align: right;
                }
            }
        }
    }
}

</style>
    